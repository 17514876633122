<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="no-padding"
        style="padding-left: 0 !important"
      >
        <v-tabs v-model="tab" class="tabs" background-color="#ffffff" dark>
          <v-tab @click="ListPayment ? routeTab(0) : ''" v-if="ListPayment">{{
            $t("Internal Payments")
          }}</v-tab>
          <v-tab
            @click="ListPayment ? routeTab(1) : routeTab(0)"
            v-if="ListGeneralPayment"
            >{{ $t("Subscriptions") }}</v-tab
          >
        </v-tabs>
      </v-col>
    </v-row>
    <v-card class="padding-top">
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-if="ListPayment">
          <v-card-text>
            <DataTableComponent :table="table"></DataTableComponent>
          </v-card-text>
        </v-tab-item>
        <v-tab-item v-if="ListGeneralPayment">
          <v-card-text>
            <DataTableComponent
              :table="generalPaymenttable"
            ></DataTableComponent>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ACL from "../../acl";
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      ListPayment: ACL.checkPermission("list-payments"),
      ListGeneralPayment: ACL.checkPermission("list-general-payments"),
      tab: 0,
      generalPaymenttable: {
        modal: "GeneralPayment",
        path: "payments",
        headers: [
          {
            text: this.$i18n.t("Transaction ID"),
            value: "transaction_id",
          },
          {
            text: this.$i18n.t("Transaction Date"),
            value: "transaction_date",
          },
          {
            text: this.$i18n.t("Payment Type"),
            value: "type",
          },
          {
            text: this.$i18n.t("Reference No"),
            value: "reference_no",
          },
          {
            text: this.$i18n.t("Amount Paid"),
            value: "amount",
          },
          {
            text: this.$i18n.t("Partner"),
            value: "partner",
          },

          {
            text: this.$i18n.t("Cashier"),
            value: "cashier",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("General Payments"),
        apiEndPoints: {
          list: "general_payment/getAllPayments",
          printPage: "/general_payment/printPdfPayment/",
          printAll: "/general_payment/printPdfAllPayments",
        },
        editedItem: {},
        permissions: {
          list: "list-general-payments",
          add: "add-general-payments",
          refund: "refund-general-payment",
          export: "export-general-payments",
          print: "print-general-payments",
          show: "show-general-payments",
          edit: "show-general-payments",
        },
        filters: {
          types: [
            { id: "Cash", name: this.$i18n.t("Cash") },
            { id: "Bank Transfer", name: this.$i18n.t("Bank Transfer") },
            { id: "Check", name: this.$i18n.t("Check") },
          ],
        },
      },
      table: {
        modal: "Payment",
        path: "payments",
        headers: [
          {
            text: this.$i18n.t("Transaction ID"),
            value: "transaction_id",
          },
          {
            text: this.$i18n.t("Transaction Date"),
            value: "transaction_date",
          },
          {
            text: this.$i18n.t("Payment Type"),
            value: "type",
          },
          {
            text: this.$i18n.t("Reference No"),
            value: "reference_no",
          },
          {
            text: this.$i18n.t("Amount Paid"),
            value: "amount",
          },
          {
            text: this.$i18n.t("Partner"),
            value: "partner",
          },
          {
            text: this.$i18n.t("Invoic number"),
            value: "invoic_number",
          },
          {
            text: this.$i18n.t("Cashier"),
            value: "cashier",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Payments"),
        apiEndPoints: {
          list: "payments/getAllPayments",
          printPage: "/payments/printPdfPayment/",
          printAll: "/payments/printPdfAllPayments",
        },
        editedItem: {},
        permissions: {
          list: "list-payments",
          add: "add-payments",
          refund: "refund-payment",
          export: "export-payments",
          print: "print-payments",
          show: "show-payments",
          edit: "edit-payments",
        },
        filters: {
          types: [
            { id: "Cash", name: this.$i18n.t("Cash") },
            { id: "Bank Transfer", name: this.$i18n.t("Bank Transfer") },
            { id: "Check", name: this.$i18n.t("Check") },
          ],
        },
      },
    };
  },
  methods: {
    routeTab(tab) {
      this.$router.push(
        {
          path: "/payments?tab=" + tab,
        },
        () => {}
      );
      this.tab = tab;
    },
  },
  components: {
    DataTableComponent,
  },
};
</script>

<style></style>
